
import React from "react"
import "../css/ImgInvitatie.css"
import ceas from "../images/ceas.png"

import dir from "../images/dir.png"

import table from "../images/table.png"

import biserica from "../images/des-biserica.png"


const ImgInvitatie =()=> {
    return (

   
      <div class="image-gol">



      <div class="dir-img">
        <img src={dir}/>
        <div class="dir-text">
          <h5>
            London
          </h5>
        </div>
        <div class="dir-text2">
          <h5>
          
          </h5>
        </div>
      </div>

      <div class="clock-img animate__animated animate__pulse animate__delay-4s animate__infinite">
        <img src={ceas} />
        <div class="clock-text">
          <h5>
            12:00
          </h5>
        </div>
      </div>

      <div class="clock1-img animate__animated animate__pulse animate__delay-4s animate__infinite">
        <img src={ceas} />
        <div class="clock-text">
          <h5>
            17:00
          </h5>
        </div>
      </div>

      <div
        class="table-img animate__animated animate__delay-5s animate__swing animate__repeat-3 animate__slowe">
        <img src={table} />
        <div class="table-text">
          <h5 style={{color: "white"}}>
            Restaurant
          </h5>
        </div>
        <div class="table-text2">
          <h4 style={{color: "white"}}>
            ”Apollonia  <br/> Events”
          </h4>
        </div>
      </div>


      <div class="bis-img animate__animated animate__delay-5s animate__swing animate__repeat-3 animate__slowe">
        <img src={biserica} />
        <div class="bis-text">
          <h5>
          Church Colindale
          </h5>
        </div>
        <div class="bis-text2">
          <h5>
          St Matthias 
          </h5>
        </div>
      </div>

     
    </div>

    )
}

export default ImgInvitatie;