import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";



import CardLocal from "../cardLocal";


import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";

import data from "../Data";
import CardInvitatie from "./CardInvitatie";
import music from '../images/bb1.png';


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 850 ? '1000px' : '850px'} opacity=".8" >
                        <div className="row text-center align-items-center mb-5 pb-5" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-5 pb-5" style={{ maxWidth: '600px', padding: '20px'  }}>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '60px', textShadow: '3px 3px 5px black, 0 0 4em black, 0 0 3em grey' }}>{item.copilul}</h1>
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower " style={{ fontSize: '36px', textShadow: '3px 3px 5px black, 0 0 4em black, 0 0 3em grey' }}>{item.savedata}<br />{item.data}</h4>
                              <Timer />  
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



       {/** <BlogSectionNoi />*/}    
<div>
    <div className="text-center" style={{color: 'whitesmoke', maxWidth: '600px', paddingTop:'50px', paddingLeft:'15px', paddingRight:'15px', margin:'auto'}}>
    <h1 className="text-center">Salutatre!</h1>
    <h4 className="text-center">
      
    Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială! 
    </h4>
    <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} style={{height: '150px'}} />
                   
                </span> 
</div>
</div>
   <CardInvitatie/>


         <CardLocal />
          {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 850 ? '900px' : '500px'} opacity=".8">
                        <div className="row text-center align-items-center pt-5 mt-5" style={{ position: 'absolute'}}>
                            <div className="col-12 pt-5 mt-5" style={{ maxWidth: '600px', padding: '30px'}}>
                                <h4 className="mt-5 pt-5" style={{textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em grey`, fontSize: '25px', color: 'greenyellow' }}>{item.title}</h4>
                                <h4 style={{textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em grey`, color: 'greenyellow' }}>{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 

         
    

            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" style={{color: 'whitesmoke'}}>
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                        {item.familia}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;