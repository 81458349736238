import restaurant from "./images/restaurant.png";
import biserica from "./images/biserica.webp";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/bbfon.png";
import imgheader from "./images/bbfon.png";
import imgheadermiini from "./images/bb4.png";
import imgheadermiinimb from "./images/bb3.png";
import logo from './images/bb1.png'



const data = {
    introData: [{
        copilul: "Lucas Ionuț",
        familia: "",
        logo: logo,
        tata: "Ionuț",
        mama: "Ancuța",
        data: "28 Septembrie 2024",
        data_confirmare: "",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "essentialbeautyharrow@gmail.com", 
       tel: "+44 7727 484348",
       phone: "tel:+44 7727 484348",
       viber: "viber://chat?number=%2Bxxx",
       whatsapp: "https://wa.me/+447727484348",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {  
            id: 1,
            img: biserica,
            title: "Sfântul Botez ",
            localul: "Church Colindale",
            name: "St Matthias ",
            data: "28 septembrie 2024, sâmbătă, ora 12:00",
            adress: "Rushgrove Ave, London NW9 6QYQY",
            harta: "https://maps.app.goo.gl/a62pShs2YGACcekF9",
            iframe: ""
        },
           { id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Apollonia Events",
            data: "28 septembrie 2024, sâmbătă, ora 17:00",
            adress: "Stanmore HA7 4AR",
            harta: "https://maps.app.goo.gl/rKasjuWR3Y95Fvq89",
            iframe: ""  
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;